.header {
  text-align: center;
  padding: 1rem;
  margin-left: auto;
  margin-right: auto;
  max-width: 80%;
}

.heading {
  color: var(--primary-50);
  font-family: Outfit;
  font-size: 45px;
  font-style: normal;
  font-weight: 700;
  text-align: center;
  padding-bottom: 1rem;
}

.subtitle {
  color: var(--black);
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px; /* 187.5% */
}

.body {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 3rem;
}

.social_handles {
  grid-column: span 2 / span 2;
  width: 100%;
}

.card {
  display: flex;
  align-items: center;
  gap: 2rem;
  background: var(--white);
  padding: 1.25rem;
  width: 100%;
  border-radius: 10px;
  box-shadow: var(--shadow-4);
}

.card__title {
  color: var(--black);
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
}

.card__details {
  color: var(--grey-600);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
}

.icon {
  display: grid;
  place-content: center;
  width: 72px;
  height: 72px;
  background: var(--white);
  border-radius: 9999px;
}

form {
  grid-column: span 3 / span 3;
  background: var(--white);
  box-shadow: var(--shadow-4);
  padding: 1.5rem 3.5rem;
  border-radius: 20px;
  width: 100%;
}

.form__group {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.form__group label {
  display: block;
  width: 100%;
  color: var(--grey-600);
  font-size: 18px;
  font-weight: 400;
}

.form__group input,
.form__group textarea {
  display: block;
  width: 100%;
  padding: 1rem;
  border-radius: 5px;
  border: 1px solid var(--grey-600);
  resize: none;
}

.button__group {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 1rem;
}

.button__group button {
  background: var(--secondary);
  padding: 1rem 2rem;
  border-radius: 5px;
  border: none;
  outline: none;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
}

@media screen and (max-width: 480px) {
  .body {
    display: flex;
    flex-direction: column;
  }

  form {
    padding: 1.5rem;
  }

  .header {
    max-width: 100%;
  }
}
