.howtopredict {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 8rem;
}

.howtopredict :first-child {
  grid-column: span 3 / span 3;
  align-self: center;
}

.howtopredict :last-child {
  align-self: center;
  grid-column: span 2 / span 2;
}

.header {
  text-align: center;
}

.subtitle {
  color: var(--primary-50);
  font-family: Outfit;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
}

.title {
  color: var(--primary-50);
  font-family: Outfit;
  font-size: 45px;
  font-style: normal;
  font-weight: 700;
}

.body {
  color: var(--black);
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px; /* 200% */
  letter-spacing: 0.5px;
}

.h2p__headings{
  font-size: large;
  color: var(--primary-50);
  font-weight: 600;
}

/* .h2p__desc{
  color: var(--primary-50);
  font-weight: 600;
} */

.slides {
  overflow: hidden;
  height: 100%;
}

.slide_image {
  width: auto;
  height: 100%;
  object-fit: cover;
  min-height: 700px;
}

@media screen and (max-width: 480px) {
  .howtopredict {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }
  .slide_image {
    min-height: 500px;
  }
}
