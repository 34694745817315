* {
  align-self: flex-start;
}

footer.container {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 7.5rem;
  padding: 3rem;
  background: var(--primary-50);
  color: var(--white);
}

footer.container > * {
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  gap: 1rem;
}

footer.container :first-child {
  grid-column: span 2 / span 2;
}

.footer__logo {
  width: auto;
  height: auto;
}

.links__list {
  display: flex;
  flex-direction: column;
  gap: 1.625rem;
}

.list__title {
  font-size: 22px;
  font-weight: 500;
}

.link {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;
}

.link::before {
  display: block;
  content: "";
  width: 10px;
  height: 10px;
  border-radius: 5rem;
  background: var(--secondary);
}

.links__list a {
  display: inline-block;
  font-size: 16px;
  color: var(--white);
  text-decoration: none;
}

.follow__list {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.follow__list a {
  display: grid;
  place-content: center;
  width: 48px;
  height: 48px;
  background: var(--white);
  border-radius: 9999px;
}

@media screen and (max-width: 480px) {
  footer.container {
    display: flex;
    flex-direction: column;
    gap: 3rem;
    padding: 1.5rem;
  }
  .links__list {
    gap: .5rem;
  }
}
