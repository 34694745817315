.container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4rem;
  padding: 2rem;
  --divider-bg: linear-gradient(
    90deg,
    rgba(192, 204, 218, 0.1) 0%,
    rgba(192, 204, 218, 0.6) 50.38%,
    rgba(192, 204, 218, 0.1) 100%
  );
  inset: 0;
}

.container::before,
.container::after {
  position: absolute;
  display: block;
  content: "";
  width: 100%;
  height: 2px;
  background: var(--divider-bg);
}

.container::before {
  top: 0 !important;
}

.container::after {
  bottom: 0 !important;
}

.emblem img {
  width: 70px;
  height: 70px;
}

@media screen and (max-width: 480px) {
  .container {
    gap: 2rem;
    overflow-x: auto;
  }
  .emblem img {
    width: 40px;
    height: 40px;
  }
}
