* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: Roboto;
  letter-spacing: var(--letterSpacing);
}

:root {
  /* COLORS  */
  /* primary */
  --primary-50: #008f8f;
  --secondary: #51ffff;
  /* grey */
  --grey-50: #f2f4f8;
  --grey-300: #dbe0ea;
  --grey-600: #adb3bf;
  --grey-1000: #333538;

  /* rest */
  --black: #222;
  --white: #fff;
  --red-light: #f8d7da;
  --red-dark: #842029;
  --green-light: #d1e7dd;
  --green-dark: #0f5132;

  /* typography */
  --headingFont: "Inconsolata", monospace;
  --bodyFont: "Montserrat", sans-serif;
  --smallText: 0.7em;
  /* rest */
  --backgroundColor: var(--grey-50);
  --textColor: var(--grey-1000);
  --borderRadius: 0.25rem;
  --letterSpacing: 1px;
  --transition: 0.3s ease-in-out all;
  --max-width: 1120px;
  --fixed-width: 600px;
  /* box shadows */
  --shadow-1: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  --shadow-2: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
  --shadow-3: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
  --shadow-4: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
    0 10px 10px -5px rgba(0, 0, 0, 0.04);
}

.padding__y {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.padding__x {
  padding-left: 3rem;
  padding-right: 3rem;
}

.padding__t {
  padding-top: 3rem;
}

.px-1 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.text-center {
  text-align: center;
}

.text-uppercase {
  text-transform: uppercase;
}

.flex {
  display: flex;
}

.flex-col {
  flex-direction: column;
}

.flex-row {
  flex-direction: row;
}

.justify-center {
  justify-content: center;
}

.items-center {
  align-items: center;
}

.justify-between {
  justify-content: space-between;
}

.gap-0\.5 {
  gap: 0.5rem;
}

.gap-1 {
  gap: 1rem;
}

.gap-2 {
  gap: 2rem;
}

.space-y-0\.5 > * {
  margin-bottom: 0.5rem;
}

.space-y-1 > * {
  margin-bottom: 1rem;
}

.space-y-1\.5 > * {
  margin-bottom: 1.5rem;
}

.space-y-2 > * {
  margin-bottom: 2rem;
}

.space-y-3 > * {
  margin-bottom: 3rem;
}

.space-y-4 > * {
  margin-bottom: 4rem;
}

*[class*="space-"] > *:last-child {
  margin-bottom: 0;
}

#app_fts_slides .tns-item:not(.tns-slide-active) + .tns-slide-active {
  scale: 0.8;
}

#app_fts_slides
  .tns-item:not(.tns-slide-active)
  + .tns-slide-active
  + .tns-slide-active
  + .tns-slide-active {
  scale: 1.2;
}

#app_fts_slides
  .tns-item:not(.tns-slide-active)
  + .tns-slide-active
  + .tns-slide-active
  + .tns-slide-active
  + .tns-slide-active
  + .tns-slide-active {
  scale: 0.8;
}

#app_fts_slides .tns-slide-active {
  transition: scale 500ms;
}

#app_fts_slides .tns-nav {
  margin-top: 1rem;
}

.tns-item:not(.tns-slide-active) + .tns-slide-active + .tns-slide-active > * {
  background: var(--primary-50) !important;
  color: var(--white) !important;
}

.tns-outer button[data-action] {
  display: none !important;
}

.tns-nav {
  display: flex;
  justify-content: center;
  gap: 0.25rem;
}

.tns-nav button {
  width: 8px;
  height: 8px;
  border-radius: 999px;
  background: var(--grey-300);
  outline: none;
  border: none;
  transition: width 500ms, background 500ms;
}

.tns-nav button.tns-nav-active {
  width: 40px;
  background: var(--primary-50);
}

.tns-ovh {
  overflow: visible !important;
}

.error-main{
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--primary-50);
  color: var(--white);
}

.error-main *{
  font-family: 'Outfit';
  text-align: center;
}

span.error-status{
  color: var(--red-dark);
  font-weight: bold;
  padding: .25rem;
  border-radius: 999px;
}

span.error-page-message{
  font-size: 12px;
  font-style: italic;
}

.goback{
  display: none!important;
  font-size: 14px;
}

.error-main .goback{
  gap: .25rem;
  padding: .25rem 1.5rem;
  color: var(--primary-50);
  background: var(--white);
  font-weight: bold;
  border-radius: 999px;
}

.error-main .goback path{
  stroke: var(--primary-50);
}

iframe{
  width: 100%;
  height: auto;
  min-height: 96rem;
}





@media screen and (max-width: 480px) {
  h1 {
    font-size: 36px !important;
    line-height: 48px !important; /* 133.333% */
  }

  p,
  a,
  label {
    font-size: 12px !important;
    line-height: 20px !important; /* 166.667% */
    letter-spacing: 0.4px !important;
  }

  .padding__y {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .padding__x {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  #app_fts_slides .tns-item:not(.tns-slide-active) + .tns-slide-active {
    scale: 1;
  }

  #app_fts_slides
    .tns-item:not(.tns-slide-active)
    + .tns-slide-active
    + .tns-slide-active
    + .tns-slide-active {
    scale: 1;
  }

  #app_fts_slides
    .tns-item:not(.tns-slide-active)
    + .tns-slide-active
    + .tns-slide-active
    + .tns-slide-active
    + .tns-slide-active
    + .tns-slide-active {
    scale: 1;
  }

  #app_fts_slides .tns-slide-active {
    transition: none;
  }

  #app_fts_slides .tns-nav {
    margin-top: 1rem;
  }
}


