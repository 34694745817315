.hero {
  /* background-image: url("./noise.png"); */
  background-color: var(--primary-50);
  background-position: center;
  background-size: cover;
  height: 70vh;
  overflow: hidden;
}

header {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 99;
}

header img {
  width: auto;
  height: 40px;
}

nav {
  display: flex;
  gap: 3rem;
}

nav a {
  display: inline-block;
  text-decoration: none;
  color: var(--white);
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
}

nav a.active {
  color: var(--secondary);
}

.menu {
  display: none;
  position: absolute;
}

.hero__content {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  height: 100%;
}

.hero__intro {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 40%;
  height: 100%;
  gap: 2rem;
}

.hero__title {
  color: var(--white);
  font-family: Outfit;
  font-size: 57px;
  font-style: normal;
  font-weight: 700;
  line-height: 72px;
  text-align: center;
  align-self: center;
}

.hero__title span {
  color: var(--secondary);
}

.hero__intro p {
  color: var(--white);
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; /* 175% */
  letter-spacing: 0.5px;
}

.hero__intro .get__app {
  display: block;
  text-decoration: none;
  color: var(--black);
  text-align: center;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.1px;
  border-radius: 8px;
  background: var(--secondary);
  width: 40%;
  padding: 1rem 2rem;
}

.hero__img {
  transform: translateY(-100px);
  user-select: none;
  pointer-events: none;
}

/* Small Devices => Phones */
@media screen and (max-width: 480px) {
  nav {
    position: absolute;
    top: 3rem;
    right: 0;
    min-width: 200px;
    flex-direction: column;
    background: var(--white);
    padding: 1rem;
    border-radius: 10px;
    gap: 0rem;
    max-height: 0px;
    overflow: hidden;
    opacity: 0;
    transition: opacity 300ms, max-height 500ms;
  }

  nav.show {
    max-height: 9999px;
    opacity: 1;
  }

  nav a {
    display: block;
    width: 100%;
    padding: 0.5rem 1rem;
    color: var(--grey-600);
    border-bottom: 1px solid var(--grey-50);
  }

  nav a:last-child {
    border-bottom: none;
  }

  .menu {
    display: block;
    position: relative;
  }

  /* nav a.active{
        color: var(--primary-50);
    } */

  .hero {
    height: auto;
  }

  .hero__content {
    flex-direction: column-reverse;
  }

  .hero__intro {
    width: 100%;
  }

  .hero__img {
    max-width: 100%;
    height: 90%;
    transform: translateY(0px);
  }
}
