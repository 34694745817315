.appfeatures {
  overflow: hidden;
}

.header {
  text-align: center;
}

.subtitle {
  color: var(--primary-50);
  font-family: Outfit;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
}

.title {
  color: var(--primary-50);
  font-family: Outfit;
  font-size: 45px;
  font-style: normal;
  font-weight: 700;
}

.card {
  display: flex;
  align-items: center;
  gap: 2rem;
  background: var(--white);
  color: var(--black);
  padding: 1.25rem;
  width: 100%;
  border-radius: 10px;
  box-shadow: var(--shadow-4);
}

.card__title {
  font-family: Outfit;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
}

.card__details {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px; /* 162.5% */
}

.icon {
  display: grid;
  place-content: center;
  width: 72px;
  height: 72px;
  background: var(--grey-50);
  border-radius: 9999px;
  flex-shrink: 0;
  align-self: center;
}

.slides {
  display: flex;
  gap: 2.5rem;
}

.app_fts_slides {
  /* overflow: hidden; */
  height: 100%;
}

.app_fts_slides__container {
  position: relative;
  width: auto;
  height: 700px;
  overflow: hidden;
}

.app_fts_slides_image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  min-height: 700px;
}

@media screen and (max-width: 480px) {
  .card {
    padding: 0.75rem;
    gap: 1rem;
  }
  .icon {
    width: 36px;
    height: 36px;
  }
  .icon svg {
    width: 12px;
    height: 12px;
  }
  .app_fts_slides_image {
    min-height: 500px;
  }
}
