.cta__container {
  position: relative;
  display: flex;
  justify-content: center;
  gap: 1rem;
  padding: 3rem;
  background-color: var(--primary-50);
  overflow: hidden;
  isolation: isolate;
}

.cta__container::before {
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  background: url("noise.png");
  background-size: cover;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.cta__image img {
  width: auto;
  height: 100%;
  scale: 1.4;
  transform: translateY(6rem);
}

.cta__details {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  color: var(--white);
  width: 35%;
}

.cta__heading {
  font-family: "Outfit";
  font-size: 72px;
  font-weight: 700;
}

.cta__description {
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px; /* 187.5% */
}

.cta__badge {
  align-self: flex-start;
}

@media screen and (max-width: 480px) {
  .cta__container {
    flex-direction: column-reverse;
  }
  .cta__details {
    width: 100%;
  }
  .cta__image img {
    max-width: 100%;
    scale: 1.3;
    transform: translateY(3rem);
  }
}
